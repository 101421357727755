import annualImg from 'assets/images/products/hecm.svg';
import Input from 'components/common/form/input/Input';
import Switch from 'components/common/form/switch/Switch';
import Text from 'components/common/typeography/text/Text';
import AnnualRatesSelect from 'components/products/annual/AnnualRatesSelect';
import FundAccessAnnual from 'components/products/annual/components/FundAccess/FundAccess';
import {API_ROUTES, FUND_ACCESS, PRODUCT_FUND_ACCESS} from 'constants/illustrator';
import {useIllustrationContext} from 'contexts/illustration-context';
import {formatCurrency, formatPercent} from 'lib/currency';
import {useTranslation} from 'lib/i18n';
import {isTenurePayment} from 'lib/product';
import {timeOut} from 'lib/utils';
import yup from 'lib/validation';
import {
  currencyField,
  getAdvancedOptionsFieldDefaultValidation,
  getFundAccessValidation,
} from 'lib/validation/validation';
import {map} from 'lodash';
import {useCallback, useEffect, useMemo, useState} from 'react';
import PaymentAtLaterAge from './components/payment-at-later-age/PaymentAtLaterAge';

const additionalStepValidation = yup.object({
  Margin: yup.string(),
  CashAtClose: yup.number().nullable(),
  ClosingCost: yup.number().nullable(),
  OverrideClosingCosts: yup.bool(),
  FundAccess: getFundAccessValidation({values: map(PRODUCT_FUND_ACCESS[API_ROUTES.ANNUAL], 'value')}),
  TenureResult: yup.object().when('Tenure', {
    is: true,
    then: (schema) =>
      schema.shape({
        ConversionAge: yup.number().when('BorrowerProfile', function (age, schema, context) {
          const {BorrowerProfile: {Age} = {}} = context.originalValue || {};

          return Age ? schema.min(Age) : schema;
        }),
        MaxMonthlyPayment: currencyField({withRequired: false}),
      }),
    otherwise: (schema) => schema.notRequired().nullable(),
  }),
});

export function useAnnualHook(props = {}) {
  const {t} = useTranslation();

  const {
    product: {
      calculation: {
        FundAccess,
        Tenure,
        TenureResult: {MaxMonthlyPayment, ConversionAge} = {},
        BorrowerProfile: {Age} = {},
        OverrideClosingCosts,
        OverrideClosingCostsKey,
        ClosingCost,
        SimplifiedCashToBorrower,
      } = {},
    } = {},
  } = props;

  const isTenureWithAge = useMemo(() => {
    if (ConversionAge > Age && isTenurePayment({Tenure})) {
      return true;
    } else {
      return false;
    }
  }, [ConversionAge, Tenure, Age]);

  const isShortToClose = useMemo(() => {
    return SimplifiedCashToBorrower < 0 ? true : false;
  }, [SimplifiedCashToBorrower]);

  const [isDisabled, setIsDisabled] = useState(true);
  const [isFundAccessLumpSum, setIsFundAccessLumpSum] = useState(false);

  const [maxCashAtClose, setMaxCashAtClose] = useState(SimplifiedCashToBorrower);

  useEffect(() => {
    setMaxCashAtClose(SimplifiedCashToBorrower);
  }, [SimplifiedCashToBorrower]);

  useEffect(() => {
    setIsDisabled(!OverrideClosingCosts);
  }, [ClosingCost, OverrideClosingCosts, OverrideClosingCostsKey]);

  useEffect(() => {
    if (FundAccess === FUND_ACCESS.LUMP_SUM) {
      setIsFundAccessLumpSum(true);
    } else {
      setIsFundAccessLumpSum(false);
    }
  }, [FundAccess]);

  const toggleDisabled = useCallback((event) => {
    setIsDisabled(!event);
  }, []);

  const illustration = useIllustrationContext();
  const formid = `illustration-${illustration.id}`;

  const submitClick = useCallback(
    async (event) => {
      return new Promise(async (resolve) => {
        await timeOut(200);

        const formButton = document.querySelector(`form[id="${formid}"] button[type="submit"]`);
        const submitButton = document.querySelector(`form[id="illustration"] button[type="submit"]`);
        if (event === FUND_ACCESS.LINE_OF_CREDIT) {
          formButton.click();
          submitButton.click();
        } else {
          formButton.click();
        }

        return resolve();
      });
    },
    [formid],
  );

  let tenurePayment = useMemo(() => {
    let validation = yup.number().nullable(true).min(0);

    if (MaxMonthlyPayment) validation = validation.max(MaxMonthlyPayment);

    return validation;
  }, [MaxMonthlyPayment]);

  const maxClaimAmount = props?.product?.calculation?.HecmMetric?.MaxClaimAmount
    ? formatCurrency(props?.product?.calculation?.HecmMetric?.MaxClaimAmount)
    : '$0.00';

  const description = t('illustratorTranslation:illustration.products.annual.description').replace(
    '<max claim amount>',
    maxClaimAmount,
  );

  // return the final product data format, including extras
  return {
    description: description,

    img: annualImg,
    imgText: 'Adjustable',
    lifetimeModelOptionHeader: useMemo(
      () => [
        {
          key: 'locYearEnd',
          group: 1,
          formatter: (props) => formatCurrency(props.locYearEnd),
          minWidth: 100,
          listData: 'locYearStart',
          condition: !isTenurePayment({Tenure}),
        },
        {
          key: 'homeValueYearEnd',
          group: 2,
          formatter: (props) => formatCurrency(props.row.homeValueYearEnd),
          minWidth: 100,
          listData: 'homeValueYearStart',
        },
        {
          key: 'loanBalanceValueYearEnd',
          group: 3,
          formatter: (props) => formatCurrency(props.row.loanBalanceValueYearEnd),
          minWidth: 100,
          listData: 'loanBalanceValueYearStart',
        },
        {
          key: 'availableLOC',
          group: 3,
          formatter: (props) => formatCurrency(props.row.availableLOC),
          minWidth: 100,
          listData: 'availableLOC',
          condition: isTenureWithAge,
        },
      ],
      [Tenure, isTenureWithAge],
    ),
    lifetimeModelChartLines: useMemo(
      () => [
        {
          key: 'locYearEnd',
          condition: !isTenurePayment({Tenure}),
        },
      ],
      [Tenure],
    ),
    additionalAdvancedOptionHeader: useMemo(
      () => [
        {
          key: 'rateAdjustments',
          group: 1,
          editable: true,
          formatter: ({row}) => formatPercent(row.rateAdjustments),
          minWidth: 100,
        },
        {
          key: 'locYearEnd',
          group: 2,
          formatter: ({row}) => formatCurrency(row.locYearEnd),
          listData: 'locYearStart',
          condition: !isTenurePayment({Tenure}),
        },
        {
          key: 'tenurePayment',
          group: 2,
          formatter: ({row}) => formatCurrency(row.tenurePayment),
          editable: ({age}) => ConversionAge < age,
          condition: isTenurePayment({Tenure}),
        },
      ],
      [ConversionAge, Tenure],
    ),
    additionalStepTitle: t('illustratorTranslation:calc.annual.details.benefits'),
    additionalStepValidation: useMemo(
      () =>
        additionalStepValidation.shape({
          advancedOptions: getAdvancedOptionsFieldDefaultValidation({
            ApiRoute: API_ROUTES.ANNUAL,
            annualPayment: yup.number(),
            monthlyPayment: yup.number(),
            tenurePayment,
          }),
        }),
      [tenurePayment],
    ),
    tableHeaderNotes: useMemo(
      () => [
        {
          key: 'availableLOCNote',
          minWidth: 100,
          listData: 'availableLOCNote',
          condition: isTenureWithAge,
        },
      ],
      [isTenureWithAge],
    ),
    additionalQuickFill: useMemo(
      () => [
        <AnnualRatesSelect order="1" name="Margin" />,
        <Input
          order="3"
          name="CashAtClose"
          type="currency"
          defaultValue={0}
          disabled={isFundAccessLumpSum || isShortToClose}
        />,
        <div order="4">
          {FundAccess === FUND_ACCESS.LINE_OF_CREDIT && !isShortToClose && (
            <Text order="4" variant="caption" colorVariant="grey">
              Cash cannot exceed {formatCurrency(maxCashAtClose)}
            </Text>
          )}
        </div>,
        <Input order="5" name="ClosingCost" type="currency" defaultValue={0} disabled={isDisabled} />,
        <Switch order="7" name="OverrideClosingCosts" defaultValue={isDisabled} onChange={toggleDisabled} />,
      ],
      [isFundAccessLumpSum, isShortToClose, FundAccess, maxCashAtClose, isDisabled, toggleDisabled],
    ),
    additionalStep: useMemo(
      () => [
        <FundAccessAnnual
          ApiRoute={API_ROUTES.ANNUAL}
          order="2"
          onClick={submitClick}
          defaultValue={FUND_ACCESS.LINE_OF_CREDIT}
        />,
        <PaymentAtLaterAge defaultAge={Age} order="8" disabled={isShortToClose} />,
        <div order="9">
          {isShortToClose && (
            <Text order="9" variant="caption" colorVariant="grey">
              Short to close. Tenure is not available.
            </Text>
          )}
        </div>,
      ],
      [Age, isShortToClose, submitClick],
    ),
  };
}
